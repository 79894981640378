<template>
  <v-card
    min-height="200"
    max-height="240"
    min-width="320"
    color="white"
    class="list-card scale-up-center"
    @click="() => { action(); detail(); window.scrollTo(0, 0); }"
  >
    <v-container class="pa-6">
      <p style="font-weight: bolder" class="mb-0 generic-text">
        {{ specialityName }}
      </p>
      <v-chip class="mb-3" x-small color="#E9EBFD" style="color: var(--v-primary); font-weight: 600">
        {{ getAppointmentTypeText(appointment.appointmentType && appointment.appointmentType.code) }}
      </v-chip>
      <p v-if="!doneType" style="font-size: 12px" class="main-strong-text">
        {{ getDateText(appointment) }}
      </p>

      <p v-if="doneType" style="font-size: 12px" class="main-strong-text">
        Realizada em {{ moment(appointment.realizationDate).format('DD/MM/YYYY') }}
      </p>

      <p style="font-size: 14px;" class="main-subtitle text-truncate mb-0">
        {{ doctorName }}
      </p>
      <p style="font-size: 14px;" class="main-strong-text text-truncate">
        {{ appointment.place || 'Local não informado' }}
      </p>
      <v-row v-if="!doneType">
        <v-col class="pr-1" :cols="!scheduledType ?  7 : 5">
          <v-row :class="scheduledType && 'mt-2'">
            <v-col class="pr-2" :cols="scheduledType ?  3 : 2">
              <pulseIcon :color="color" />
            </v-col>
            <v-col v-if="!scheduledType" class="pl-0" cols="10">
              <p style="font-size: 12px" class="main-strong-text mb-10">
                Aguardando <br/> agendamento sugerido
              </p>
            </v-col>
            <v-col v-if="scheduledType" class="pl-0" cols="9">
              <p  style="font-size: 12px" class="main-strong-text mb-10">
                Agendado
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-6 pl-0" :cols="scheduledType ?  7 : 5">
          <v-btn class="card-btn" @click="action" :color="color">
            {{ btnTitle }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="start" v-if="doneType">
        <v-col cols="8">
          <v-row v-if="appointment.realizationWithDelay" class="mt-3">
            <v-col cols="2">
              <pulseIcon color="neonGreen"/>
            </v-col>
            <v-col cols="10">
              <p style="font-size: 12px" class="main-strong-text mb-0">
                Realizada fora do prazo
              </p>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="3">
              <img
                src="/img/icons/home/medal-badge.svg"
                style="width: 40px; height: 40px"
              >
            </v-col>
            <v-col class="mt-2" cols="9">
              <p style="font-size: 12px" class="main-strong-text mb-0">
                Realizada no prazo
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment';
import pulseIcon from '@/components/pulse-icon';
import { appointmentTypes, appointmentsStatus } from '../../common/constants';

export default {
  name: 'AppointmentCard',
  components: { pulseIcon },
  props: {
    appointment: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'blue'
    },
    doneType: {
      default: false,
      type: Boolean
    },
    scheduledType: {
      default: false,
      type: Boolean
    },
    action: {
      type: Function,
      required: false
    },
    detail: {
      type: Function,
      required: false
    },
    btnTitle: {
      type: String,
      default: 'Registrar'
    }
  },
  data() {
    return {
      moment
    }
  },
  computed: {
    specialityName() {
      if(this.appointment.medicalSpeciality && this.appointment.medicalSpeciality.name){
        if(this.appointment.medicalSpeciality.name === 'Outros'){
          return `${this.appointment.medicalSpeciality.name} - ${this.appointment.comment}`
        }else{
          return this.appointment.medicalSpeciality.name
        }
      }else{
        return 'Consulta não informada'
      }
    },
    doctorName() {
      if(this.appointment && this.appointment.doctorName) {
        return this.appointment.doctorName !== ' ' ?
            this.appointment.doctorName : 'Médico não informado'
      }else {
        return 'Médico não informado';
      }
    }
  },
  methods: {
    getAppointmentTypeText(type) {
      if(type === appointmentTypes.self)
        return 'Iniciativa própria'

      if(type === appointmentTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === appointmentTypes.refer)
        return 'Indicação médica'
    },
    getDateText(appointment) {
      if(appointment.status && appointment.status === appointmentsStatus.waiting)
        return `Realize até ${ moment(appointment.realizationUntilDate).format('DD/MM/YYYY') }`

      if(appointment.status && appointment.status === appointmentsStatus.scheduled)
        return `Data agendada ${ moment(appointment.scheduledDate).format('DD/MM/YYYY') }`
    },
  }
}
</script>

<style scoped>
.card-btn {
  border-radius: 20px !important;
  color: white !important;
  text-transform: initial !important;
  font-weight: bolder !important;
}

.v-icon.v-icon  {
  color: var(--v-tertiary) !important;
  font-size: 15px !important;
  padding-left: 20px !important;
}

.list-card {
  border-radius: 20px !important;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  max-width: 90vw !important;
  width: auto;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.3s ease-in both;
  animation: scale-up-center 0.3s ease-in both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.option-list-card {
  width: 100% !important;
  height: 65px;
}
</style>