<template>
 <v-container
   :style="{ height: '100vh', display: 'block', width: '100vw', background: !register ? 'rgba(255, 255, 255, .5)' : 'white'}"
   class="pa-6 pt-2 fluid bg-effect"
 >
   <backArrow v-if="!register" />
   <v-layout class="mt-1" v-if="!register && !registerSuccess" align-end justify-end>
     <img @click="$router.push('/principais-duvidas')" src="/img/nick/question-nick.svg" style="width: 55px" >
   </v-layout>
   <v-layout v-if="!register && !registerSuccess" align-start justify-start>
     <v-row>
       <v-col cols="6" class="pb-0">
<!--         <v-select-->
<!--           :items="['aa', 'ss']"-->
<!--           rounded-->
<!--           background-color="#e4e7f8"-->
<!--           append-icon="fas fa-chevron-down"-->
<!--           height="35"-->
<!--         />-->
       </v-col>
     </v-row>
   </v-layout>
   <Totalizer
     v-if="!register && !registerSuccess"
     class="mb-2 pt-10"
     :totals="totals"
     title="Consultas"
     title-icon="/img/appointments/stethoscope-image.svg"
     color="#706CE0"
     :height="80"
     :tile-icon-right="40"
     :tile-icon-top="80"
     :tile-icon-width="130"
     :tile-icon-height="110"
   />
   <v-layout v-if="!register && !registerSuccess" justify-center align-center>
     <v-card style="background: rgba(231, 234, 254, .6);" class="option-list-card list-card mb-6">
        <v-row justify="center">
          <v-col class="pl-6 pr-0 pt-5" cols="7">
            <div
              :class="!activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="!activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center filter-text generic-text mt-1 mb-0"
                @click="() => activeFilter = !activeFilter"
              >
                Próximas e Atrasadas
              </p>
            </div>
          </v-col>
          <v-col class="pl-0 pr-7 pt-5" cols="5">
            <div
              :class="activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center history-margin-adjust filter-text generic-text mb-0"
                @click="() => activeFilter = !activeFilter"
              >
                Histórico
              </p>
            </div>
          </v-col>
        </v-row>
     </v-card>
   </v-layout>
   <v-layout v-if="!register && !registerSuccess" align-center justify-center>
     <v-row v-if="!activeFilter" align="center" justify="center">
       <v-col v-if="lateAppointments.length > 0" cols="12">
         <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Atrasadas</p>
       </v-col>
       <v-col v-for="appointment in lateAppointments" :key="appointment.id" cols="12">
         <AppointmentCard
           color="tertiary"
           :appointment="appointment"
           :action="() => {
             currentAppointment = appointment;
             realizeAppointment = false;
             register = true;
           }"
           :detail="() => {
             detailAppointment = true;
             realizeAppointment = true;
           }"
         />
       </v-col>
       <v-col cols="12" v-if="nextAppointments.length > 0 || waitingAppointments.length > 0">
         <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Próximas</p>
       </v-col>
       <v-col v-for="appointment in waitingAppointments" :key="appointment.id" cols="12">
         <AppointmentCard
           color="cyan"
           :appointment="appointment"
           :action="() => {
             currentAppointment = appointment;
             realizeAppointment = false;
             register = true;
           }"
           :detail="() => {
             detailAppointment = true;
             realizeAppointment = true;
           }"
         />
       </v-col>
       <v-col v-for="appointment in nextAppointments" :key="appointment.id" cols="12">
         <AppointmentCard
           color="green"
           :appointment="appointment"
           btn-title="Comparecimento"
           scheduled-type
           :action="() => {
             currentAppointment = appointment;
             realizeAppointment = true;
             register = true;
           }"
           :detail="() => {
             detailAppointment = true;
             realizeAppointment = true;
           }"
         />
       </v-col>
     </v-row>
     <v-row v-if="activeFilter && fullfilledAppointments.length > 0" align="center" justify="center">
       <v-col cols="12">
         <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Realizadas</p>
       </v-col>
       <v-col v-for="appointment in fullfilledAppointments" :key="appointment.id" cols="12">
         <AppointmentCard
           :appointment="appointment"
           done-type
           :detail="() => {
             detailAppointment = true;
             realizeAppointment = true;
           }"
           :action="() => {
             currentAppointment = appointment;
             realizeAppointment = true;
             register = true;
           }"
         />
       </v-col>
     </v-row>
   </v-layout>

   <AppointmentRegister
     :success="() => registerSuccess = true"
     :close="() => { register = false; detailAppointment = false; realizeAppointment = false; currentAppointment = null }"
     v-if="register"
     :appointment="currentAppointment"
     :clear-appointment="() => currentAppointment = null"
     :realize-type="realizeAppointment"
     :detail-only="detailAppointment"
     v-on:updateAppointmentTotalizers="updateAppointmentTotalizers"
     v-on:verifyIsRealized="verifyIsRealized"
   />

   <v-container v-if="registerSuccess">
     <v-card class="success-card" flat>
       <v-container class="pa-7">
         <img src="/img/appointments/circle-stethoscope.svg" class="success-img">
         <p style="font-size: 36px" class="mt-16 pt-12 main-title">
           Consulta Registrada
         </p>

         <p v-if="!isRealized" style="font-size: 24px" class="pt-4 main-text">
           Não esqueça de informar se foi à consulta
         </p>
         <generic-btn
           style="width: 85%; bottom: 100px; position: absolute"
           :btn-props="{}"
           :on-click="() => registerSuccess = false"
         >
           Ok
         </generic-btn>
       </v-container>
     </v-card>
   </v-container>

   <v-card
     v-if="!register && !registerSuccess"
     color="#706CE0"
     class="plus-icon"
     elevation="10"
     @click="() => register = true"
   >
     <v-layout justify-center align-center>
       <img src="/img/icons/common/plus-icon.svg" class="mt-4">
     </v-layout>
   </v-card>
 </v-container>
</template>

<script>
import backArrow from '../../components/covid/back-arrow';
import Totalizer from '@/components/generic-totalizer';
import AppointmentCard from '../../components/appointment/AppointmentCard';
import AppointmentRegister from '../../components/appointment/AppointmentRegister';
import MedicalAppointmentEventService from '../../services/healthcare/MedicalAppointmentEvent';
import { mapMutations } from 'vuex';
import moment from 'moment';
import { appointmentsStatus, appointmentTypes } from '../../common/constants';


export default {
  name: 'Appointment',
  components: {
    backArrow,
    Totalizer,
    AppointmentCard,
    AppointmentRegister
  },
  watch: {
    register() {
      this.getAppointments();
    }
  },
  data() {
    return {
      totals: [],
      activeFilter: false,
      lateAppointments: [],
      fullfilledAppointments: [],
      nextAppointments: [],
      waitingAppointments: [],
      moment,
      register: false,
      registerSuccess: false,
      currentAppointment: null,
      realizeAppointment: false,
      detailAppointment: false,
      isRealized: false,
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    updateAppointmentTotalizers(){
      this.getTotals();
    },
    verifyIsRealized(){
      this.isRealized = true
    },
    getAppointmentTypeText(type) {
      if(type === appointmentTypes.self)
        return 'Iniciativa própria'

      if(type === appointmentTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === appointmentTypes.refer)
        return 'Indicação médica'
    },
    getAppointments() {
      this.loading(true);

      this._appointmentEventService.getAppointmentsFindAllByUser()
        .then(({ data }) => {
          this.lateAppointments = data.filter((appointment) => {
            return appointment.delayed && appointment.status === appointmentsStatus.scheduled
          });

          this.fullfilledAppointments = data.filter((appointment) => {
            return appointment.status === appointmentsStatus.fullfilled ||
              appointment.realizationDate
          });

          this.nextAppointments = data.filter((appointment) => {
            return appointment.status === appointmentsStatus.scheduled
              && !appointment.delayed
          });

          this.waitingAppointments = data.filter((appointment) => {
            return appointment.status === appointmentsStatus.waiting
          });
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar consultas!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getTotals() {
      this.loading(true);

      this._appointmentEventService.getTotalizers()
        .then(({ data }) => {
          this.totals = [{
            label: 'Sugestões',
            value: data.totalSuggestionLastYear
          }, {
            label: 'Próximas',
            value: data.totalSuggestionAndScheduledLastYear
          }, {
            label: 'Histórico',
            value: data.totalRealizeds
          },]
        })
        .catch(() =>
            this.showmsg({
              text: "Erro ao carregar totalizadores!",
              type: "error",
            })
        )
        .finally(() => this.loading(false));
    },
  },
  mounted() {
    this.getTotals();
    this.getAppointments();
  },
  beforeCreate() {
    this._appointmentEventService = new MedicalAppointmentEventService();
  },
}
</script>

<style >
/*.v-icon.v-icon  {*/
/*  color: var(--v-tertiary) !important;*/
/*  font-size: 15px !important;*/
/*  padding-left: 20px !important;*/
/*}*/

.bg-effect {
  background: rgba(255, 255, 255, .5);
  mix-blend-mode: normal;
  padding: 10px;
  height: 100% !important;
}

.list-card {
  background-blend-mode: multiply !important;
  border-radius: 20px !important;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
}

.option-list-card {
  width: 100% !important;
  height: 65px;
}

.plus-icon {
  border-radius: 50% !important;
  height: 60px;
  width: 60px;
  position: fixed !important;
  right: 30px;
  bottom: 30px;
}

.success-card {
  position: absolute;
  bottom: 0;
  left: 7.5%;
  height: 80vh;
  width: 85vw;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  z-index: 10;
}

@media (max-width: 389px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 13px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 4px !important;
  }
}

@media (min-width: 390px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 16px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 2px !important;
  }
}

@media (max-width: 379px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 24%;
    top: -100px;
  }
}

@media (min-width: 380px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 27.5%;
    top: -100px;
  }
}
</style>
