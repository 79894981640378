import HttpService from '../HttpService'

export default class MedicalAppointmentEventService {
    constructor() {
        this._httpService = new HttpService(`healthcare/medical_appointment_event`);
    }

    async getTotalizers(){
        return await this._httpService.get('/get_medical_appointment_event_totalizers/');
    }

    async getAppointmentsFindAllByUser(){
        return await this._httpService.get('/find_all_by_user');
    }

    async getDoctorHistory(){
        return await this._httpService.get('/find_doctor_history');
    }

    async createAppointment(data){
        return await this._httpService.post('/', data);
    }

    async updateAppointment(id, data){
        return await this._httpService.put(`/${id}`, data);
    }

    async realizeAppointment(data){
        return await this._httpService.post('/realize_appointment', data);
    }

}
